import { render, staticRenderFns } from "./pdfImg.vue?vue&type=template&id=45a4fcf4&scoped=true&"
import script from "./pdfImg.vue?vue&type=script&lang=js&"
export * from "./pdfImg.vue?vue&type=script&lang=js&"
import style0 from "./pdfImg.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./pdfImg.vue?vue&type=style&index=1&id=45a4fcf4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45a4fcf4",
  null
  
)

export default component.exports