import Cookies from 'js-cookie'

const TokenKey = 'Authorization'
/*
* 设置setLocalStorage
* */
export function setLocalStorage(key, value) {
    window.localStorage.setItem(key, window.JSON.stringify(value))
}
/*
* 获取getLocalStorage
* */
export function getLocalStorage(key) {
    return window.JSON.parse(window.localStorage.getItem(key) || '[]')
}

// 删除getLocalStorage
export function removeLocalStorage(key) {
    return window.localStorage.removeItem(key)
}

/*
* 设置setSessionStorage
* */
export function setSessionStorage(key, value) {
    window.sessionStorage.setItem(key, window.JSON.stringify(value))
}

/*
* 获取getSessionStorage
* */
export function getSessionStorage(key) {
    return window.JSON.parse(window.sessionStorage.getItem(key) || '[]')
}

/*
* 获取getToken
* */
export function getToken() {
    return Cookies.get(TokenKey)
}

/*
* 设置setToken
* */
export function setToken(token) {
    return Cookies.set(TokenKey, token)
}

/*
* 移除removeToken
* */
export function removeToken() {
    return Cookies.remove(TokenKey)
}

// 手机号码校验
export function MobileVerification(str) {
    let reg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/;
    return reg.test(str);
}

// 身份证号码校验
export function CadIdVerification(str) {
    let reg = /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0[1-9]|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/;
    return reg.test(str);
}

//获取网页链接参数
export function getUrlParam() {
    var url = location.search; //获取url中"?"符后的字串   
    var theRequest = new Object();
    if (url.indexOf("?") != -1) {
        let str = url.substr(1);
        let strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
    }
    return theRequest;
}

export function getQueryString(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
}

export function randomn(n) {
    if (n > 21) return null;
    return parseInt((Math.random() + 1) * Math.pow(10, n - 1));
}

export function getyyyyMMdd() {
    var d = new Date();
    var curr_date = d.getDate();
    var curr_month = d.getMonth() + 1;
    var curr_year = d.getFullYear();
    String(curr_month).length < 2 ? (curr_month = "0" + curr_month) : curr_month;
    String(curr_date).length < 2 ? (curr_date = "0" + curr_date) : curr_date;
    var yyyyMMdd = curr_year + "" + curr_month + "" + curr_date;
    return yyyyMMdd;
}
// 用于截止报名 传 2022-09-05 00:00:00
export function timeguoqi(time){ 
	const date = new Date(time);
	if(date.getTime() < Date.now()){
	    return false
	}else{
	     return true
	} 
}




Date.prototype.format = function (format) {
    if (!format) {
        format = "yyyy-MM-dd hh:mm:ss";
    }

    var o = {
        "M+": this.getMonth() + 1, // month
        "d+": this.getDate(), // day
        "H+": this.getHours(), // hour
        "h+": this.getHours(), // hour
        "m+": this.getMinutes(), // minute
        "s+": this.getSeconds(), // second
        "q+": Math.floor((this.getMonth() + 3) / 3), // quarter
        S: this.getMilliseconds()
    };

    if (/(y+)/.test(format)) {
        format = format.replace(
            RegExp.$1,
            (this.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
    }

    for (var k in o) {
        if (new RegExp("(" + k + ")").test(format)) {
            format = format.replace(
                RegExp.$1,
                RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
            );
        }
    }

    return format;
};


export function getDay() {
	let time = new Date(),
	year = time.getFullYear(),
	month = time.getMonth() + 1,
	day = time.getDate(),
	timeStem = time.getTime()
	return `${year}/${month}/${day}/${timeStem}.jpg`
}