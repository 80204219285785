<template>
	<div class="pdfImg">
		<div   class="canvas" :style="{height: height + 'px', width: width + 'px'}" ref="xuekacn">
		  <canvas ref="the-canvas" :height="height" :width="width" id="canvastt">
		  </canvas>   
		  
		</div>  
		<img :src="imgurl" alt="" /> 
	</div>
</template>

<script>
import PDFJS from 'pdfjs-dist';
import workerSrc from 'pdfjs-dist/build/pdf.worker.entry';
PDFJS.workerSrc = workerSrc;
import html2canvas from "html2canvas"
export default {
	name: 'pdfImg',
	data() {
		return {
			pdfDoc: null,
			currentPage: 1,
			total: 0,
			scale: 1,
			rotate: 0,
			height: 842,
			width: 595,
			imgurl:'', 
		};
	}, 
	props:{ 
		url: {
		    type: String,
		    default: ''
		}, 
	},
	created () {
		this.$toast.loading({
			message: '正在绘制中,请稍后',
			forbidClick: true,
			overlay: true,
			duration: 0
		})
		
		this._loadFile(this.url); 
		var _this = this;
		
		var timer = setInterval(function () {
			_this.$toast.clear();
		    // 判断文档和所有子资源(图片、音视频等)已完成加载
		    if (document.readyState === 'complete') {
		        //执行方法
		        _this.getPdfImage()
		        window.clearInterval(timer)
		    }
		}, 2000)
	},
	mounted () {}, 
	methods: {
		getPdfImage(){   
			var _fixType = function(type) {
				type = type.toLowerCase().replace(/jpg/i, 'jpeg');
				var r = type.match(/png|jpeg|bmp|gif/)[0];
				return 'image/' + r;
			};
			var type = 'png';
			var fixType = _fixType(type);
			
			 let canvasurl = this.$refs['the-canvas'] .toDataURL(fixType); 
			 canvasurl = canvasurl.replace(fixType, 'image/octet-stream');  
			console.log('srcs',canvasurl)
			this.imgurl = canvasurl
		  
		},
		_renderPage(currentPage, scale, rotate) { 
		  if (currentPage < 1) {
		    currentPage = 1
		  }
		  if (currentPage > this.total) {
		    currentPage = this.total
		  }
		  this.currentPage = currentPage
		  if (scale < 0.25) {
		    return
		  }
		  this.scale = scale
		  this.rotate = rotate % 360;
		  this.pdfDoc.getPage(currentPage).then((page) => {
		    let viewport = page.getViewport({scale, rotation: rotate});
			console.log('viewport',viewport)
		    let canvas = this.$refs['the-canvas'];
		    let context = canvas.getContext('2d');
		    this.height = viewport.height;
		    this.width = viewport.width;  
		    let renderContext = {
		      canvasContext: context,
		      viewport: viewport
		    }; 
			page.render(renderContext);  
			  
		  }) 
		 },
		_loadFile(data) {
		  // https://cdn.jsdelivr.net/npm/pdfjs-dist@2.4.456/cmaps/
		  const CMAP_URL = 'https://unpkg.zhimg.com/pdfjs-dist@2.4.456/cmaps/'; 
		  PDFJS.getDocument(data).promise.then((pdf) => {
			console.log('pdf',pdf)
		    this.pdfDoc = pdf
		    this.total = pdf.numPages
		    this.scale = 0.4
		    this.currentPage = 1
		    this.rotate = 0
		    this.$nextTick(() => {
		      this._renderPage(this.currentPage, this.scale, this.rotate);
		    });
		  })
		}, 
	}
};
</script>
<style lang="scss">
@import '@/assets/css/index.scss';
// 改写部分vant  Dialog弹出框
.van-dialog {
	width: px2rem(640);
	font-size: px2rem(32);
}
.van-dialog__message {
	padding: px2rem(52) px2rem(48);
	font-size: px2rem(28);
	line-height: px2rem(40);
}
.van-dialog__header {
	padding-top: px2rem(52);
	line-height: px2rem(58);
}
.van-dialog__cancel,
.van-dialog__confirm {
	height: px2rem(96);
}
.van-button {
	font-size: px2rem(32);
}
.van-hairline--top::after {
	border-top-width: px2rem(4);
}
.van-hairline--left::after {
	border-left-width: px2rem(4);
}
.van-picker__toolbar{
	height: px2rem(100) !important;
}
.van-loading__spinner {
	width: px2rem(100) !important;
	height: px2rem(100) !important;
}
.van-toast {
	width: auto;
}
</style>

<style scoped>
	.pdfImg{
		width: 100%;	
		height: 100%;
		/* overflow: hidden; */
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	} 
	
	img{
		width: 100%;
	}
	
	.canvas{
		position: fixed;
		let:-100000% ;
		top: -999999999%
	}
	 
	
</style>
