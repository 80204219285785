<template>
	<div class="wrapper" v-show="viewShow">
		<section class="wrapper_top">
			<img :src="require('@/assets/image/fh2.png')" alt="" @click="fh()" />
			證書一覽
		</section>
		<section class="wrapper_centent" v-if="objZSimgData != null">
			<img :src="objZSimgData.imageUrl" alt=""  v-if="objZSimgData.imageUrl != null" class="wrapper_centent_img"/>
			<pdfImg :url="objZSimgData.pdfUrl" v-else/> 
		</section>

		<div class="fromBut" @click="fromBut">證書下載</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import pdfImg from '@/components/pdfImg'; 
import {getDay} from '@/utils/auth';
import download from '@/utils/download';
export default {
	components: {},
	name: 'achievement',
	data() {
		return {
			// https://oss.chineserd.com/group1/M00/2E/35/rBcjNmPgZOmABYPOABHhdG_XYh0192.pdf 
			objZSimgData:null,
		};
	},
	watch: {},
	components: { pdfImg },
	created() {
		this.objZSimgData = JSON.parse(this.getQueryString('url')); 
	},
	mounted() {},
	computed: {
		...mapState(['isPc', 'viewShow']),
		viewShow() {
			return this.$store.state.viewShow;
		}
	},
	methods: {
		getQueryString(name) {
			return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null;
		},
		fh() {
			history.go(-1); //返回首页
		},
		
		fromBut(){
			download(this.objZSimgData.pdfUrl)
		},
		
		fileLinkToStreamDownload(url) {
			let fileName = getDay();
			let reg = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~\/])+$/;
			if (!reg.test(url)) {
				throw new Error('传入参数不合法,不是标准的文件链接');
			} else {
				let xhr = new XMLHttpRequest();
				xhr.open('get', url, true);
				xhr.setRequestHeader('Content-Type', `application/pdf`);
				xhr.responseType = 'blob';
				let that = this;
				xhr.onload = function() {
					if (this.status == 200) {
						//接受二进制文件流
						var blob = this.response;
						that.downloadExportFile(blob, fileName);
					}
				};
				xhr.send();
			}
		},
		downloadExportFile(blob, tagFileName) {
			let downloadElement = document.createElement('a');
			let href = blob;
			if (typeof blob == 'string') {
				downloadElement.target = '_blank';
			} else {
				href = window.URL.createObjectURL(blob); //创建下载的链接
			}
			downloadElement.href = href;
			downloadElement.download =
				tagFileName +
				//下载后文件名
				document.body.appendChild(downloadElement);
			downloadElement.click(); //点击下载
			document.body.removeChild(downloadElement); //下载完成移除元素
			if (typeof blob != 'string') {
				window.URL.revokeObjectURL(href); //释放掉blob对象
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/index.scss';

.wrapper {
	width: 100%;
	height: 100%;
	position: relative;
	overflow-x: hidden;
	background: #ffffff;
	padding-top: px2rem(80);
	box-sizing: border-box;

	.wrapper_top {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: px2rem(80);
		text-align: center;
		line-height: px2rem(80);
		font-size: px2rem(36);
		background: #ffffff;
		font-family: PingFang SC-Semibold, PingFang SC;
		font-weight: 600;
		color: #000000;

		img {
			width: px2rem(56);
			height: px2rem(56);
			position: absolute;
			left: px2rem(32);
			top: 0;
			bottom: 0;
			margin: auto;
		}
	}

	.wrapper_centent {
		width: 100%;
		height: calc(100% - #{px2rem(80)});
		padding: px2rem(32);
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			width: 100%;
		}
	}

	.fromBut {
		width: calc(100% - #{px2rem(32)} - #{px2rem(32)});
		position: fixed;
		bottom: 3%;
		left: 0;
		right: 0;
		padding-left: px2rem(32);
		padding-right: px2rem(32);
		box-sizing: border-box;
		height: px2rem(88);
		line-height: px2rem(88);
		background: #122ab0;
		border-radius: px2rem(92);
		margin: auto;
		font-size: px2rem(32);
		font-family: PingFang SC-Semibold, PingFang SC !important;
		font-weight: 600;
		color: #ffffff;
		box-shadow: 0px px2rem(8) px2rem(8) 0px rgba(18, 42, 176, 0.3);
	}
}
</style>
